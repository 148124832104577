.fa-regular,
.far {
  font-family: "Font Awesome 6 Free";
}

.maxheight-400-auto{
  max-height: 400px;
  overflow: auto;
}



.backdrop {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  cursor: pointer;
}

.company-list .company-card h5 a {
  font-size: 18px;
  color: #25d2c3;
  line-height: 22px;
  display: block;
}

.company-list .company-card h5 a:hover {
  color: #ffd468;
  text-decoration: none;
}

.company-list .badge {
  height: 22px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  padding: 0 24.1px;
  font-size: 10px;
  font-weight: 400;
}

.company-list .badge-danger {
  background: #ff0000;
}

.company-list .company-card button {
  border-radius: 10px;
  width: 86px;
  height: 22px !important;
  line-height: 22px;
  text-align: center;
  border: 1px solid #ffd468;
  color: #ffd468;
  padding: 0;
  line-height: 16px;
  font-size: 10px;
  font-weight: 600;
}

.company-list .company-card h5 {
  margin-bottom: 12px;
}

.refferal-btn {
  position: absolute;
  top: 8px;
  right: 0px;
}

.company-list small.text-muted span {
  font-style: normal;
}

.company-list .rounded-circle {
  position: absolute;
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #25d2c3;
  border-radius: 50% !important;
  padding: 0;
  bottom: 19px;
  right: 23px;
}

.company-list small.text-muted div {
  margin-bottom: -2px;
}

.company-list .company-card .card-body {
  padding-bottom: 10px !important;
}

.company-list .disable-claim-btn {
  background: #ffd468;
  color: #fff !important;
}

.claim-btn:hover {
  color: #fff !important;
}

.company-list .company-card .box-header h5 a {
  color: #fff;
}

.company-list .badge-success {
  background: #01d31a;
  border: 1px solid #fff;
}

.box-header .card-subtitle {
  margin-top: -3px;
}

.box-card .text-muted {
  color: #25d2c3 !important;
  font-weight: 500;
}

.company-list {
  overflow-x: hidden;
}

.badge-secondary {
  background: #ffd468;
  width: fit-content!important;
  max-width: 200px!important;
}

.badge-listing {
  max-width: 85%;
}

.purchase-bordered-btn {
  font-weight: 500;
  border: 3px solid #25d2c3;
  color: #25d2c3;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.purchase-bordered-btn:hover {
  border: 3px solid #ffd468;
  color: #ffd468;
}

.payBtn {
  background: #fff !important;
  border: 3px solid #ffd468 !important;
  color: #ffd468 !important;
}

.payBtn:hover {
  font-weight: 500 !important;
  border: 3px solid #25d2c3 !important;
  color: #25d2c3 !important;
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

/* FOR DASHBORAD */
#change-log {
  position: absolute !important;
  border-radius: 5px !important;
  top: 30px !important;
}

.dashboard-header {
  display: flex;
  align-items: center;
  padding: 20px 30px 0;
}

.mobile-dashboard-header {
  display: none;
}

.tbl-info span {
  display: flex;
  align-items: center;
  font-style: normal;
}

.tbl-info span p {
  margin-bottom: 0;
  margin-right: 7px;
  font-style: italic;
  /* margin-bottom: 10px; */
}

.tbl-info span i {
  margin-right: 7px;
}

.tbl-info span a {
  text-decoration: underline;
  font-weight: 300;
}

.upcoming-table .card-body {
  max-height: 75vh !important;
  overflow-y: auto;
}

.upcoming-table .pagination {
  justify-content: end;
  margin-top: 30px !important;
}

.upcoming-table .pagination>li>a {
  padding: 8px 10px;
}

/* FOR NOTIFICATION */
.notification-col {
  display: flex;
}

.notification-upper {
  width: 100%;
}

.notification-upper .notification-search {
  margin: 0;
  max-width: 100%;
}

.notification-table .card-header {
  padding-top: 0;
}

/* .header-bg h1 {padding: 10px 30px 0;} */
.notification-table table {
  width: 100%;
}

.notification-table tr:first-child td {
  border-top: none;
}

.notification-table table tr td strong {
  margin-left: 5px;
}

.rounded-circle {
  border-radius: 50% !important;
  width: 40px;
  height: 40px;
  padding: 0;
  line-height: 32px;
}

.rounded-circle:focus {
  background: var(--primary);
  border-color: var(--primary);
}

.notification-search input {
  border: 1px solid #d8d8d8;
  border-radius: 10px;
  width: 100%;
  height: 45px;
  padding: 0 18px;
  font-size: 14px;
  font-weight: 300;
  padding-left: 45px;
}

.notification-search {
  max-width: 371px;
  margin: 0 auto;
  position: relative;
  margin-top: 32px;
}

.notification-search button {
  position: absolute;
  border: none;
  background: none;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
  padding: 0;
  color: #7c7c7c;
}

.booking-detail-page .card {
  height: 100%;
}

.booking-detail-page .card p:last-child {
  margin-bottom: 0;
}

.book-service-comment {
  margin-top: 30px;
  height: auto !important;
}

.book-service-comment .booking-comment .bg-light {
  margin-left: 10px;
}

.booking-detail-page .cancel-paddingbtm {
  padding: 0 20px;
}

.booking-detail-page .cancel-paddingbtm button {
  background: #ff0000;
  height: 45px;
  font-weight: 500;
}

.booking-detail-page .cancel-paddingbtm .card {
  max-width: 200px;
  border: none;
}

.book-service-comment .submit-comments {
  height: 45px;
  padding: 0 21px;
  font-weight: 500;
}

.user-icon img {
  max-width: 24px;
}

.user-icon {
  position: absolute;
  right: 150px;
  z-index: 998;
  margin-right: 39px;
  top: 30px;
}

.book-service-comment .form-comment textarea {
  padding-top: 17px;
  height: 62px;
}

.notification-table tr {
  display: flex;
}

.notification-table tr td:first-child {
  max-width: 180px;
}

.notification-table tr td {
  width: 100%;
}

.notification-table tr td:last-child {
  max-width: 56px;
}

.full-width {
  max-width: 100% !important;
}

.desktop-show-search {
  display: block;
}

.desktop-show-search .user-icon {
  display: none;
}

.desktop-show-search .dashboard-header {
  display: none;
}

/* FOR BUISNESS MANAGER */
#accountTabs .btn-secondary {
  background: var(--primary);
  border-color: var(--primary);
  border-right: none;
}

#accountTabs .btn-danger {
  border-left: none;
}

#accountTabs table tr td {
  vertical-align: middle;
}

.list-group-item p {
  font-size: 14px;
  margin-top: 5px;
}

.list-group-item label {
  font-weight: 500;
}

.mobile-dahboard-header {
  display: flex;
  align-items: center;
}

.mobile-show {
  display: none;
}

/* .table-striped tbody tr:nth-of-type(odd) td{
  background: #fff !important;
}
.table-striped tbody tr:nth-of-type(even) td{
  background: #f5f5f5;
} */

.no-appointment {
  background: transparent !important;
}

#accountTabs table tr td {
  border: none;
}

.buisness-manager-page .buisness-table {
  max-height: 75vh !important;
  overflow-y: auto;
}

.buisness-manager-page .buisness-table thead {
  position: static;
  top: 0;
  background: #fff;
}

.buisness-manager-page .table thead th {
  border-bottom: 1px solid #dee2e6;
}

.buisness-manager-page .table tbody i {
  color: #25d2c3;
}

.list-group-item:hover {
  text-decoration: none;
}

.list-group-flush>.list-group-item {
  color: #191b1c;
  background-color: #fff7f0;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  padding: 12px 24px;
}

.list-group-flush>.list-group-item.active {
  color: #191b1c;
}

.calender-btn {
  background: #00d330;
  border-color: #00d330;
  font-weight: 500;
  margin-top: 10px;
}

.add-btn button {
  border: none;
  background: #25d2c3;
  border-radius: 10px;
  color: #fff;
  padding: 10.5px 28.5px;
}

.add-btn {
  margin-top: 27px;
  text-align: right;
}

.edit-btn button {
  border: none;
  background: #25d2c3;
  border-radius: 10px;
  color: #fff;
  padding: 6.8px 12px;
  min-width: 75px;
}

/* FOR REGISTRATION  */
.registration-page img {
  max-width: 100px;
  margin-bottom: 15px;
}

.logintitle {
  text-align: left;
  font-size: 16px;
  line-height: 26px;
  padding: 0 5px;
  font-weight: 300;
}

.logintitle i {
  margin-left: 16px;
}

.registration-page form input {
  border-radius: 10px;
  border: 1px solid #d8d8d8;
  padding: 11.62px 18px;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  /* height: 45px; */
  font-weight: 300;
  margin-bottom: 0 !important;
}

.registration-page select::after {
  content: "\25BC";
  /* Unicode character for down arrow */
  font-size: 14px;
  /* Adjust the size as needed */
  position: absolute;
  right: 15px;
  /* Adjust the position as needed */
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  /* Ensure the arrow does not interfere with select interaction */
}

.registration-page select {
  height: 43px;
}

.registration-page form textarea {
  border-radius: 10px;
  border: 1px solid #d8d8d8;
  padding: 14px 18px;
  font-size: 12px;
  line-height: 18px;
  color: #000;
  font-weight: 300;
  margin-bottom: 0 !important;
}

.registration-page form .form-group {
  margin-bottom: 10px !important;
  width: 50%;
  padding: 0 5px;
}

.registration-page form .first-input input {
  margin-bottom: 10px !important;
}

.registration-page p.lead {
  margin-bottom: 44px;
}

.registration-page .form-outer {
  width: 100%;
}

.registration-page form .first-input {
  width: 100%;
  margin-bottom: 0 !important;
}

.registration-page {
  max-width: 900px;
  margin: 0 auto;
}

.registration-page form {
  display: flex;
  flex-wrap: wrap;
}

.registration-page form .first-input .col-6 {
  padding: 0 5px !important;
}

.registration-page form .first-input {
  margin: 0 !important;
  padding: 0 !important;
}

.registration-page .form-heading {
  width: 100%;
  text-align: left;
}

.calender-integration {
  width: 100%;
  /* background: #f5f5f5; */
  padding: 32px 52px 22px;
  margin-top: 34px;
}

.white-bg {
  background-color: #fff !important;
}

.registration-page .fa-circle-info {
  font-size: 17px;
  line-height: 22px;
  position: relative;
  top: 2.6px;
}

.registration-page .calender-integration .form-group {
  width: 100%;
  position: relative;
}

.registration-page .calender-integration .form-group input {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
}

.plusbtnclass {
  margin: 0;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.calender-integration p {
  margin: 0;
  margin-bottom: 14px;
}

.add-services,
.form-location,
.buisness-information {
  width: 100%;
}

.registration-page .form-group {
  position: relative;
}

.register-btm {
  width: 100%;
  margin-top: 66px;
}

.register-btm input {
  max-width: 225px;
  width: 100%;
  border: none !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}

.register-btm p {
  font-size: 10px;
  max-width: 202px;
  margin: 0 auto;
  line-height: 16px;
}

.register-btm .signup-link {
  font-size: 12px;
}

.logintitle span,
.form-heading span {
  font-size: 14px;
}

.form-heading {
  padding: 0 5px;
}

.form-location,
.buisness-information,
.add-services {
  display: flex;
  flex-wrap: wrap;
}

.form-location h4,
.add-services h4 {
  width: 100%;
}

.login-information {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.buisness-hours {
  width: 100%;
  margin-top: 30px;
}

.buisness-hours h4 {
  width: 100%;
}

.buisness-hours .grid-container {
  display: block;
}

.grid-item .grid-col {
  display: flex;
  flex-wrap: wrap;
}

.grid-item .grid-item {
  width: 50%;
}

.buisness-hours label {
  width: 100%;
  text-align: left;
}

.buisness-hours .switch {
  display: block;
  width: 20px;
  height: 12px;
  margin-bottom: 8px;
}

.slider {
  height: 20px;
  width: 36px;
}

.slider:before {
  height: 18px;
  width: 18px;
  left: 1px;
  bottom: 1px;
}

input:checked+.slider:before {
  transform: translateX(15.5px);
}

input:checked+.slider {
  background-color: #ffad68 !important;
  box-shadow: none;
}

.grid-col {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -24px;
}

.grid-col .grid-item {
  width: 50%;
  padding: 0 24px;
  position: relative;
  background: none;
}

.grid-col .grid-item:after {
  content: "to";
  font-size: 12px;
  color: #707070;
  line-height: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -6px;
}

.grid-col .grid-item:last-child:after {
  display: none;
}

.grid-container>label {
  font-weight: 600;
  font-size: 12px;
  color: #333333;
  line-height: 18px;
}

.grid-item select {
  font-size: 12px;
  line-height: 18px;
  color: #7c7c7c;
  height: 45px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../src/images/arrow-down-sign-to-navigate.png);
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 10px;
}

.buisness-information {
  margin-top: 30px;
  gap: 20px;
}

/* FOR PROFILE PAGE */
/* .profile-page input {
  height: 45px;
  font-weight: 300;
} */
/* .profile-page button {
  height: 45px;
  font-weight: 500;
  padding: 0 32px;
} */
.profile-page .form-control[readonly] {
  background: #f8f8f8;
  border: 1px solid #d8d8d8;
  color: #7c7c7c;
}

.fa-eye-slash {
  color: #7c7c7c;
}

.profile-page .form-group label {
  color: #333333;
}

.text-danger {
  color: #ff0000 !important;
}

.btn-danger {
  background: #ff0000 !important;
  border-color: #ff0000 !important;
}

#delete-account h4 {
  margin-bottom: 0;
}

#delete-account .card-body {
  padding-top: 0;
}

#delete-account button {
  padding: 5px 18px;
  margin-top: 29px;
}

/* .profile-page .user-icon {
  top: 33px;
  right: 180px;
} */
.registration-page .popup {
  width: 100%;
  max-width: 600px;
  box-shadow: 0px 30px 30px #0000001a;
  border-radius: 6px;
  padding-top: 70px;
  text-align: left;
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 75px;
  height: 90vh;
  overflow-y: auto;
}

.registration-page .popup h2 {
  color: #333333;
  font-size: 20px;
  line-height: 30px;
}

.registration-page .popup .close {
  font-weight: 300;
  color: #25d2c3;
  font-size: 40px;
  right: 15px;
  top: 3px;
}

.registration-page .popup label {
  margin-right: 0;
}

.registration-page .popup .form-group {
  width: 100%;
}

.registration-page .popup .form-group input {
  width: 100%;
}

.registration-page .popup .form-group textarea {
  width: 100%;
}

.registration-page .popup form {
  display: block;
}

.required-label {
  margin-bottom: 6px;
  /* margin-left: 32px; */
}

.label {
  margin-left: 32px;
}

#required-label {
  margin-bottom: 6px;
  margin-left: 32px;
}

.contact-method {
  margin-top: 36px;
  margin-bottom: 18px;
}

.contact-method i {
  margin-left: 10px;
}

.contact-method-col label:first-child {
  width: 20px;
  height: 12px;
  margin-right: 11px;
}

.contact-method-col {
  display: flex;
  align-items: center;
}

.registration-page .create-service-btn {
  font-size: 16px;
  padding: 11px 63px;
  font-weight: 500;
  color: #fff;
  box-shadow: none;
  border: none;
  margin-top: 36px;
  /* margin-left: 172px; */
}

.registration-page .create-save-service-btn {
  font-size: 16px;
  padding: 11px 63px;
  font-weight: 500;
  color: #fff;
  box-shadow: none;
  border: none;
  width: fit-content;
  position: absolute;
  bottom: 20px;
  right: 20px;
  /* margin-left: 172px; */
}

/* .create-service-btn {
  margin-left: 172px;
} */

/* FOR SIGN UP PAGE */
.signup-page .business {
  background: #fff;
  box-shadow: 0px 3px 6px #0000001a;
  border: 1px solid #dddddd;
  border-radius: 10px;
  padding: 20px 40px;
  margin-right: 34px;
}

.signup-page .business:last-child {
  margin-right: 0;
}

.signup-row {
  display: flex;
  justify-content: center;
  margin-top: 43px;
}

.signup-page .business i {
  display: block;
  margin-bottom: 11px;
}

.signup-page .business i img {
  max-width: 64px;
  width: 100%;
}

.signup-page .business span {
  display: block;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  color: #ffab68;
}

.signup-page h1 {
  font-size: 28px;
  line-height: 42px;
  color: #ffab68 !important;
  font-weight: 800;
  margin-bottom: 13px;
}

.signup-page p.lead {
  font-size: 20px;
  line-height: 26px;
  max-width: 260px;
  margin: 0 auto;
}

.signup-bottom-txt {
  font-size: 12px;
  line-height: 18px;
  color: #7c7c7c;
  margin: 0 !important;
  margin-top: 29px !important;
}

.signup-bottom-txt a {
  display: inline-block;
  margin-left: 3px;
}

/* FOR FEATURE LISTING */
.feature-listing .form-check input[type="radio"]:after {
  content: "";
  display: block;
  width: 7px;
  height: 7px;
  margin: 0;
  border-radius: 50%;
  position: absolute;
  top: 48%;
  left: 48%;
  transform: translate(-50%, -50%);
}

.feature-listing .form-check input[type="radio"]:checked:after {
  background: #27d7cf;
}

.feature-listing .card {
  border: none;
}

.purchaseRadio .form-check {
  margin-bottom: 10px;
}

/* FOR FAVORITE PAGE */
.box-header {
  /* background: #25d2c3; */
  margin: -22px;
  border-radius: 20px 20px 0 0;
  padding: 10px 10px 5px 10px;
  margin-bottom: 10px;
}

.featured-table .booking-date {
  margin-left: 10px;
}

@media only screen and (max-width: 1700px) {
  .upcoming-table table.noti-table {
    overflow-x: auto;
  }

  .upcoming-table table {
    width: 100%;
    overflow-x: auto;
  }
}

@media only screen and (max-width: 1600px) {
  /* --- */

  .dashboard_flex .row {
    flex-direction: column !important;
  }
}

@media only screen and (max-width: 1499px) {
  .setting-overflow {
    overflow: scroll;
  }
}

@media only screen and (max-width: 1199px) {
  #margin_custom {
    margin: 0 20px !important;
  }

  .edit_comp_heading {
    display: block;
  }

  .break_hed {
    width: 100%;
    max-width: 50%;
  }

  .side_gap {
    position: absolute;
    right: -15px !important;
  }

  .company-list .company-card .card-body {
    padding: 15px;
  }

  .company-list .rounded-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    line-height: 21px;
    top: 68px;
    right: 10px;
  }

  .company-list .rounded-circle i {
    font-size: 12px;
  }

  .company-list .badge {
    padding: 0px;
    width: 70px;
    margin-right: 3px;
    text-align: center !important;
  }

  .box-header {
    height: 72px;
    margin: -1rem -1rem 0;
    padding: 8px 15px;
  }

  .company-list .company-card h5 a {
    font-size: 15px;
    line-height: 18px;
  }

  .company-list .company-card h5 {
    margin-bottom: 6px;
  }

  #calendar-pop .popup h2 {
    font-size: 20px;
  }

  #calendar-pop .popup .calender_pop_btn_container input {
    font-size: 15px;
  }

  #calendar-pop .popup .calender_pop_btn_container .save-btn {
    font-size: 15px;
    width: fit-content;
    display: flex;
    align-self: flex-end;
  }

  .user-icon {
    margin-right: 25px !important;
  }

  #pop_martop {
    max-width: 80% !important;
  }
}

/* --- */

@media only screen and (max-width: 600px) {
  #pop_martop {
    max-width: 100% !important;
  }

  .login_bg::after {
    top: 0 !important;
  }

  .form_width {
    width: 80%;
  }

  .sigin_logo {
    width: 144px !important;
    height: 101px !important;
  }

  .edit_del_btn a i {
    font-size: 17px;
  }

  /* .pop_cancle {
    margin-right: 15px;
    padding: 8px 36px !important;
  } */
}

@media only screen and (max-width: 400px) {
  .pop_cancle {
    margin-right: 15px;
    padding: 8px 38px !important;
  }

  .form_width {
    width: 100%;
  }

  .login_bg::after {
    height: 91px;
  }

  .forget_bg:after {
    height: 120px !important;
  }

  .your_bussi_title {
    font-size: 15px !important;
    margin-bottom: 5px !important;
  }

  .add_buss {
    font-size: 13px;
    margin-right: 5px;
  }

  .free_month_btn {
    font-size: 13px;
  }

  .search-bar {
    margin-bottom: 20px;
  }

  .methode p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 360px) {
  .pop_cancle {
    margin-right: 15px;
    padding: 8px 30px !important;
  }
}

@media only screen and (max-width: 767px) {
  #custom_header .card-header {
    display: block !important;
  }

  #custom_header .card-body {
    padding: 0 20px 20px !important;
  }

  /* .hide {
    margin-top: 5pxzxxxxxxxxxxxxxxxx;
    visibility: visible;
  }

  

 

  .show2 {
    visibility: hidden;
  } */

  .signup_header {
    display: none;
  }

  .edit_header a {
    display: none;
  }

  .edit_header h4 {
    margin-left: 0 !important;
  }

  .login-information,
  .add-services,
  .buisness-hours {
    padding: 0 50px !important;
  }

  .custom_class {
    padding-left: 0 !important;
  }

  .flex_column tr td {
    line-height: 18px !important;
  }

  .login_form_main_container {
    padding: 3px 50px !important;
  }

  .login_bg {
    background: transparent linear-gradient(115deg, #ffd468 0%, #ffab68 100%) 0% 0% no-repeat;
  }

  .success_popup {
    height: 77vh;
  }

  .login_bg::after {
    content: "";
    height: 100px;
    width: 100%;
    top: -6px;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    opacity: 0.5;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    background-image: url("../src/images/wave-bg.png");
  }

  .signin_text {
    font-size: 28px;
    margin-bottom: 38px;
    color: white !important;
  }

  .none_text {
    display: none;
  }

  #email,
  #password {
    font-size: 16px;
    /* background-color: white !important; */
  }

  .sigin_logo {
    visibility: visible;
    width: 171px;
    height: 122px;
    margin-bottom: 20px;
  }

  .submit_btn {
    font-size: 16px;
  }

  .forget_signin {
    font-size: 12px;
    font-weight: 300;
  }

  .dont_account {
    margin-top: 44px !important;
    font-size: 12px;
  }

  .dont_account a {
    display: block;
    color: white;
    font-size: 16px;
    width: fit-content;
    margin: 0 auto;
    padding: 11px 52px;
    margin-top: 10px;
    border-radius: 10px;
    transition: 0.6s;
    text-decoration: none;
    border: 2px solid white;
  }

  .dont_account a:hover {
    color: #ffab68;
    background-color: white !important;
  }

  .forget_signin a {
    color: #ffffff;
  }

  .forget_bg {
    background-color: white !important;
  }

  .forget_bg:after {
    content: "";
    position: absolute;
    width: 100%;
    top: 0;

    height: 112px !important;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../src/images/wave-bg.png");
  }

  .forget_bg::before {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 0;
    height: 112px !important;
    transform: rotate(180deg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../src/images/wave-bg.png");
  }

  .forget_text {
    font-size: 28px;
    color: #ffab68 !important;
    margin-bottom: 12px;
  }

  .enter_email {
    font-size: 12px;
    margin-bottom: 24px;
  }

  .card-header {
    margin-bottom: 0 !important;
  }

  /* .card-header:first-child {
    padding: 13px !important;
  } */

  .your_bussi_title {
    visibility: visible;
    position: relative;
    margin-bottom: 10px !important;
  }

  .mid_hed {
    visibility: visible;
    position: relative;
  }

  .card-header {
    margin-bottom: 29px;
  }

  .hidden {
    /* position: absolute; */
    visibility: hidden;
  }

  th {
    border: none !important;
  }

  .main_search {
    padding: 0 15px !important;
  }

  .biss_mag_bg::before {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 0;
    top: 0;
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../src/images/wave-bg.png");
  }

  .upcoom_event {
    visibility: visible;
  }

  .list-group-flush a {
    font-size: 14px;
  }

  .calender_icon {
    font-size: 20px;
  }

  .google_calender,
  .calendly {
    padding: 15px 12px !important;
  }

  .google_calender p,
  .calendly p {
    font-size: 14px;
  }

  .google_map:after {
    content: "";
    width: 100%;
    top: 0;
    background-size: 100%;
    height: 100px;
    background-repeat: no-repeat;
    position: absolute;
    /* opacity: 0.5; */
    /* -webkit-transform: scaleX(-1);
    transform: scaleX(-1); */
    /* background-image: url("../src/images/wave-bg.svg"); */
  }

  /* .map_section {
    /* margin-top: 120px ; */

  .title_map {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px 0 20px 0;
  }

  .title_map h3 {
    color: #ffab68;
    font-size: 28px;
    left: 0;
  }

  .user i {
    top: 29px;
    left: 24px;
    font-size: 27px;
    position: relative;
    color: #ffab68;
  }

  #mapSearchCol {
    padding-left: 0;
    padding-right: 0;
  }

  .favorite_hed {
    text-align: center;
  }

  .favorite_hed h4 {
    margin-left: 0 !important;
    font-size: 28px !important;
    color: #ffab68 !important;
  }

  .favorite_bg:after {
    content: "";
    width: 100%;
    top: 0;
    height: 100px;
    left: 0;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: absolute;
    /* opacity: 0.5; */
    /* -webkit-transform: scaleX(-1);
  transform: scaleX(-1); */
    /* background-image: url("../src/images/wave-bg.png"); */
    display: block;
    background: url(../src/images/wave-bg.svg) no-repeat;
    background-size: 100%;
  }

  .calender_pop_btn_container {
    align-items: flex-start;
  }

  .calender_pop_btn_container input {
    margin: 15px 0 !important;
    width: 100%;
  }

  .success_page_items:after {
    content: "";
    width: 100%;
    top: 0;
    left: 0;
    height: 100px;
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    /* opacity: 0.5; */
    /* -webkit-transform: scaleX(-1);
transform: scaleX(-1); */
    background-image: url("../src/images/wave-bg.png");
  }

  .success_page_items::before {
    content: "";
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 100px;
    transform: rotate(180deg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-image: url("../src/images/wave-bg.png");
  }

  .registration-page .pop_custom_height {
    padding-bottom: 100px !important;
  }
}

@media only screen and (max-width: 991px) {
  input[type="range"]+datalist option {
    font-size: 18px;
    margin-top: 18px;
  }

  .edit_service_form {
    padding: 34px 31px 0px 31px !important;
  }

  .edit-service {
    margin-left: 0 !important;
    /* padding-left: 50px !important; */
  }

  .company-list .company-card h5 a {
    font-size: 16px;
    line-height: 20px;
  }

  .text-muted {
    float: left;
    /* width: 82%; */
  }

  .floatright {
    float: right !important;
    margin: 0;
  }

  .company-list .company-card h5 a {
    display: inline-block;
    vertical-align: top;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 5px;
  }

  .box-header {
    height: 75px;
    padding: 8px 10px;
    margin: -0.75rem -0.75rem 0;
  }

  .company-list .company-card .card-body {
    padding: 10px;
  }

  .company-card .listComp {
    display: flex;
  }

  .company-card .starComp {
    margin-top: 5px;
  }

  /* FOR BOOKING DETAIL */
  .booking-detail-page .card {
    height: auto;
  }

  .book-service-comment {
    margin-top: 0;
  }

  /* .user-icon.desktop-show {
    display: none;
  } */
  .booking-detail-page h2 {
    font-size: 24px;
  }

  .booking-detail-page .card-title,
  .booking-detail-page .from-sent {
    margin-left: 0;
  }

  #calendar-pop .popup {
    font-size: 15px;
    max-width: 60% !important;
  }
}

@media only screen and (max-width: 767px) {

  /* .company-list {
    padding-right: 1rem !important;
  } */
  .google_map .user {
    visibility: visible;
  }

  .select_one {
    margin-bottom: 6px;
  }

  .edit_service_form {
    padding: 24px 31px 0px 31px !important;
  }

  .edit-service h4 {
    margin-top: 71px !important;
    font-size: 14px !important;
  }

  .upcoming-table .card:first-child {
    margin: 67px 0 0 0 !important;
  }

  .buisness_user_icon {
    top: 46px !important;
    left: 12px !important;
    cursor: pointer;
  }

  .your_bussi_title {
    margin-left: 0 !important;
  }

  .company-list .company-card h5 a {
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;
  }

  .company-list .badge {
    margin: 2px 0;
    max-width: 81px;
    width: 81px;
  }

  .box-header {
    height: 94px;
    position: relative;
  }

  .company-list .company-card h5 {
    margin-bottom: 11px;
    margin-left: 0;
  }

  #companyListCol .badge-listing .badge-pill {
    margin: 3px 0;
  }

  #main-content {
    margin-left: 0;
    margin-right:0;
    width: 100%;
    padding-left: 0;
    margin-bottom: 10rem;
  }

  /* FOR DASHBOARD */
  #main-content {
    padding-top: 0;
  }

  .mobile-dashboard-header {
    display: none;
    padding-top: 57px;
  }

  /* .dashboard-header {
    display: block;
    padding: 0;
  } */

  .header-bg {
    display: block;
    height: fit-content !important;
    text-align: center;
    /* background: url(../src/images/wave-bg.svg) no-repeat; */
    background-size: 100%;
    /* margin-bottom: 20px; */
  }

  .find-bg {
    display: block;
    height: fit-content !important;
    /* background: url(../src/images/wave-bg.svg) no-repeat; */
    background-size: 100%;
    margin-bottom: 20px;
  }

  .header-notification {
    display: block;
    height: fit-content !important;
    text-align: center;
    /* background: url(../src/images/wave-bg.svg) no-repeat; */
    background-size: 100%;
    margin-bottom: 20px;
  }

  .header-bg-edit {
    display: block;
    height: 100px;
    text-align: center;
  }

  /* .edit_service_main_container::after {
    content: "";
    background: url(../src/images/wave-bg-bottom.svg) no-repeat;
    background-size: 100%;
    height: 130px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
  } */

  /* .edit_service_main_container::before {
    content: "";
    position: absolute;
    width: 100%;
    top: 0;
    height: 130px;
    background-size: 100%;
    background-repeat: no-repeat;
    /* background-image: url("../src/images/wave-bg.png"); */
    /* display: block;
    background: url(../src/images/wave-bg.svg) no-repeat;
    background-size: 100%;
  } */

  .dashborad-logo {
    max-width: 84px;
    margin: 0 auto;
    margin-bottom: 8px;
  }

  .dashboard-header h1 {
    color: #ffab68;
  }

  .dashborad-logo img {
    width: 100%;
  }

  .card-header:first-child h4 {
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
  }

  .user-icon {
    visibility: visible;
  }

  .user-icon img {
    max-width: 24px;
  }

  .user-icon {
    position: absolute;
    top: 22px;
    left: 20px;
    right: auto;
    color: #ffab68;
  }

  .buisness-table .booking-item-row td {
    border-top: none !important;
  }

  .buisness-table .booking-item-row {
    display: block;
    width: 100%;
  }

  .buisness-table .booking-item-row td {
    display: block;
    padding: 0;
  }

  .buisness-table .booking-item-row td time {
    font-size: 16px;
    color: #ffab68;
  }

  .buisness-table .booking-item-row td a {
    display: block;
  }

  .buisness-table .booking-item-row td a time {
    font-weight: 600;
  }

  .buisness-table .booking-item-row td a br {
    display: none;
  }

  .buisness-table .booking-service-name a,
  .buisness-table .booking-item-row td {
    font-weight: 300 !important;
    color: #333333;
    line-height: 30px;
    font-size: 14px;
  }

  .booking-service-name br {
    display: none;
  }

  .buisness-table .booking-item-row:last-child {
    margin-bottom: 0;
  }

  .buisness-table .booking-item-row:nth-child(even) {
    background: #f8f8f8;
  }

  .upcoming-table .card-body {
    padding: 15px 4px;
  }

  .upcoming-table .booking-item-row {
    padding: 20px 15px !important;
  }

  .main-page .card-body {
    padding-top: 0 !important;
  }

  /* .main-page .buisness-table .rounded-circle {
    top: 40%;
  } */

  .buisness-table th {
    width: 50% !important;
    border-top: none !important;
    border-bottom: 1px solid #dee2e6 !important;
  }

  .buisness-table table {
    width: 100%;
  }

  .buisness-table .booking-item-row {
    width: 100%;
    display: block;
  }

  .buisness-table tr {
    width: 100%;
    /* display: flex !important; */
  }

  .upcoming-table table {
    width: 100%;
  }

  .buisness-table tr td {
    display: inline-block;
    width: 50%;
    font-size: 10px;
    line-height: 16px;
    padding-right: 50px;
    position: relative;
  }

  .buisness-table tr .booking-service-name {
    font-size: 14px;
    line-height: 16px;
    padding-right: 15px;
  }

  .buisness-table .rounded-circle {
    width: 32px;
    height: 32px;
    line-height: 32px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 64%;
    transform: translateY(-50%);
  }

  /* FOR NOTIFICATION */
  .notification-page .user-icon {
    top: 13px;
    left: 38px;
  }

  .desktop-show {
    display: none;
  }

  #change-log {
    display: none;
  }

  .notification-table tr td {
    border: none;
    vertical-align: middle;
  }

  .notification-table tr:first-child td {
    max-width: 45px;
  }

  .notification-table table tr td span {
    display: block;
    font-size: 14px;
    line-height: 21px;
  }

  .notification-table table tr td strong {
    margin-left: 0;
    font-size: 16px;
    line-height: 25px;
    font-weight: 500 !important;
  }

  .notification-table tr td:first-child {
    width: 75px;
    font-size: 12px;
    line-height: 18px;
  }

  .notification-table tr:nth-child(even) {
    background: #f5f5f5;
  }

  .upcoming-table {
    margin-top: -30px !important;
    margin-bottom: 40px;
  }

  .notification-table tr:first-child td {
    max-width: 100%;
  }

  .notification-table tr td:first-child {
    width: 100%;
    max-width: 85px;
  }

  .notification-upper .notification-search {
    margin: 0 auto;
  }

  /* FOR BOOKING DETAIL */
  .booking-detail-page .card-body {
    padding-top: 0;
  }

  .booking-detail-page .card-header {
    margin-bottom: 16px;
  }

  .booking-detail-page .card-title {
    margin-top: 0 !important;
    color: #ffab68;
    font-size: 16px;
    line-height: 25px;
  }

  .booking-detail-page p,
  .booking-detail-page .from-sent {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 0;
  }

  .book-service-comment .submit-comments {
    margin-top: 0;
  }

  .book-service-comment .form-comment textarea {
    margin-bottom: 0 !important;
    height: 45px;
    padding-top: 10px;
    font-size: 14px;
    line-height: 21px;
    color: #7c7c7c;
    font-weight: 300;
  }

  .form-comment .attached-comment {
    background: none;
    border: none;
    color: #25d2c3;
  }

  .booking-detail-page h2 {
    display: none;
  }

  .booking-detail-page .card-body {
    text-align: left;
  }

  .booking-detail-page .book-serv {
    margin-top: 56px;
  }

  .mobile-top-arrow {
    position: absolute;
    left: 16px;
    top: -9px;
    background: none;
    border: none;
    padding: 0;
    height: auto;
    width: auto;
    margin: 0 !important;
    line-height: normal;
    color: #25d2c3;
  }

  .from-sent {
    margin-top: 0;
  }

  .booking-detail-page .card-title {
    margin-bottom: 10px;
  }

  /* FOR BUISNESS MANAGER */
  .buisness-manager-page .mobile-dahboard-header h2 {
    display: none;
  }

  .top-gap {
    margin-top: 23px;
  }

  .buisness-manager-page {
    text-align: left;
    padding: 0 0px 60px;
  }

  .mobile-top-header {
    text-align: left;
  }

  .mobile-top-header h4 {
    color: #25d2c3;
    font-weight: 600;
    margin-bottom: 0;
  }

  .mobile-top-header p {
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 0;
  }

  .mobile-top-header {
    margin-bottom: 25px;
  }

  .list-group-item {
    border: none;
  }

  .list-group.list-group-flush {
    border-radius: 10px !important;
    padding: 4px;
  }

  .mobile-show {
    display: block;
  }

  .list-group-flush>.list-group-item {
    border-radius: 5px;
  }

  .buisness-manager-page .card {
    border-radius: 10px !important;
  }

  .buisness-table tr td:first-child {
    font-size: 12px;
    line-height: 18px;
  }

  .buisness-table tr td:first-child,
  .buisness-table tr th:first-child {
    width: 33% !important;
  }

  .buisness-table tr td span {
    font-size: 14px;
    line-height: 21px;
  }

  .buisness-table tr td,
  .buisness-table tr th {
    width: 100% !important;
    font-size: 14px;
    padding-right: 10px;
  }

  .buisness-table tr td:nth-child(2),
  .buisness-table tr th:nth-child(2) {
    width: 100% !important;
  }

  .buisness-table tr td:nth-child(3),
  .buisness-table tr th:nth-child(3) {
    /* width: 30% !important; */
  }

  .card-header:first-child h4 {
    margin-left: 0;
  }

  .buisness-table thead {
    /* display: none; */
  }

  .list-group-flush>.list-group-item {
    max-width: 100%;
  }

  .buisness-manager-page .mobile-top-arrow {
    top: 15px;
  }

  .calender-tab .card-header {
    display: none;
  }

  .calender-tab .btn-success span {
    display: none;
  }

  .calender-tab .btn-success {
    background: none;
    border: none;
    color: #00d330;
    position: absolute;
    right: -10px;
    top: -31px;
  }

  .calender-tab .calender-txt {
    display: none;
  }

  .calender-tab h6 {
    margin-bottom: 0;
  }

  .calender-tab .card-body {
    padding: 0;
  }

  .calender-tab .card-body li {
    /* padding: 32px 20px !important; */
    border-radius: 0 !important;
  }

  .calender-tab .card-body li:nth-child(even) {
    background: #f5f5f5;
  }

  .calender-tab ul {
    padding: 1px !important;
    overflow: hidden;
  }

  .upcoming-table .dashboard-table tr td,
  .upcoming-table .dashboard-table tr th {
    width: 100% !important;
  }

  .calender-btn {
    background: none;
    color: #00d330;
    border: none;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    right: -15px;
    top: -31px;
  }

  .calender-tab h6 {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    font-weight: 400;
  }

  .calender-tab .card-body {
    padding: 0;
  }

  .calender-tab .card-body ul {
    padding: 0;
  }

  .calender-tab .card-body ul li {
    padding: 32px 20px;
  }

  .calender-tab .card-body ul li:nth-child(even) {
    background: #f5f5f5;
  }

  .calender-tab .list-group-item .row {
    margin-bottom: 0;
  }

  /* FOR REGISTRATION FORM */
  .hideMenu {
    display: none !important;
  }

  .bottom-wave:after {
    content: "";
    background: url(../src/images/wave-bg-bottom.svg) no-repeat;
    background-size: 100%;
    height: 130px;
    position: absolute;
    width: 100%;
    left: 0;
    /* bottom: 0; */
  }

  #bottom-wave:after {
    content: "";
    background: url(../src/images/wave-bg-bottom.svg) no-repeat;
    background-size: 100%;
    height: 85px;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 1px;
  }

  .bottom-wave-edit:after {
    content: "";
    height: 95px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
  }

  .bottom-wave {
    position: relative;
  }

  .register-btm {
    margin-bottom: 80px;
  }

  .registration-page .mobile-top-arrow {
    top: 30px;
  }

  .registration-page form .form-group {
    width: 100%;
  }

  .buisness-information {
    background: #f5f5f5;
    padding: 30px 50px;
  }

  .buisness-information-edit {
    /* padding: 30px 32px; */
    /* margin-top: -125px; */
  }

  .buisness-hours-edit {
    /* padding: 30px 32px; */
    margin-top: -125px;
  }

  .registration-page {
    padding: 0;
    padding-top: 110px;
  }

  .set-padding {
    padding-top: 0px;
  }

  .login-information,
  .add-services,
  .buisness-hours {
    padding: 0 0px;
  }

  .logintitle {
    margin-left: -8px;
    font-size: 14px;
    line-height: 26px;
  }

  .logintitle span,
  .form-heading span {
    font-size: 12px;
    line-height: 26px;
  }

  .form-location {
    margin-top: 14px;
  }

  /* FOR PROFILE PAGE */
  .profile-page .card-header h4 {
    font-size: 14px;
    line-height: 21px;
    color: #333333;
    font-weight: 300;
  }

  .profile-page .card-header {
    margin-bottom: 9px;
    padding: 17px 22px 0;
  }

  .profile-page .card-body {
    padding: 17px 22px 17px;
  }

  .profile-page input {
    font-size: 14px;
  }

  .profile-page {
    padding-bottom: 50px !important;
  }

  .profile-page .form-group {
    margin-bottom: 10px;
  }

  .profile-page button {
    margin-top: 10px;
  }

  .profile-page .form-group:nth-child(2) {
    margin-top: 19px;
  }

  .upcoming-table table.noti-table {
    width: 100%;
  }

  .registration-page .popup h2 {
    font-size: 14px;
    line-height: 21px;
    margin-bottom: 26px;
  }

  .required-label {
    font-size: 10px;
    line-height: 16px;
    margin-left: 32px;
  }

  .contact-method {
    font-size: 12px;
    line-height: 18px;
  }

  .registration-page .popup label {
    font-size: 12px;
    line-height: 18px;
  }

  .registration-page .popup {
    max-width: 90%;
  }

  .registration-page .create-service-btn {
    max-width: 248px;
    margin: 0 auto;
    display: block;
    margin-top: 36px;
  }

  .signup-page .mobile-top-arrow {
    top: 30px;
  }

  /* FOR FEATURE LISTING */
  .feature-listing .card-header {
    display: flex !important;
    padding: 0;
  }

  .custome-card-body {
    margin-top: 0px !important;
  }

  /* .feature-listing .header-right {
    display: none;
  } */
  .feature-listing table tbody {
    border-radius: 10px;
    position: relative;
    box-shadow: inset 0 0 0 4px #fff;
  }

  .feature-listing table tbody:after {
    content: "";
    border: 1px solid #d8d8d8;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 10px;
  }

  .feature-listing .featured-table {
    width: 100% !important;
  }

  .feature-listing .table thead th {
    width: 57%;
    border: none;
    font-size: 12px;
    line-height: 18px;
    /* font-weight: 400; */
  }

  .feature-listing .table tbody td {
    font-size: 12px;
    line-height: 18px;
    font-weight: 300;
  }

  .feature-listing .table tr:nth-of-type(even) {
    box-shadow: inset 0 0 0 4px #fff;
  }

  .feature-listing .table tbody td:nth-child(3),
  .feature-listing .table thead th:nth-child(3) {
    width: 100%;
    min-width: 95px;
  }

  .feature-listing .table tbody td:nth-child(4),
  .feature-listing .table thead th:nth-child(4) {
    max-width: 59px;
    width: auto;
    padding: 0.75rem 0;
  }

  .feature-listing .card-header h4 {
    font-size: 14px;
    line-height: 26px;
    font-weight: 500;
    margin: 0;
  }

  .purchaseRadio .form-check {
    float: left;
    width: 50%;
    margin-bottom: 10px;
  }

  .purchase-btn {
    float: left;
    width: 100%;
  }

  /* .box-header {
    background: url(../src/images/wave-shape.png) no-repeat;
    background-size: 100% 100%;
  } */
  .box-card h5 {
    margin-top: 2px !important;
  }

  /* FOR FAVORITE PAGE */
  /* .favorite-page .card-title {
    margin-left: 0;
  } */
  .favorite-page .text-muted {
    margin-bottom: 10px;
  }

  .favorite-page .company-list .company-card h5 {
    margin-top: 4px;
  }

  .featured-table .booking-date.badge {
    padding: 3px 5px;
  }

  .desktop-show-search .dashboard-header {
    display: block;
  }

  .desktop-show-search .user-icon {
    display: block;
  }

  .feature-listing .card-title {
    margin-left: 0 !important;
  }

  .future_listing_card .card-header .card-title {
    margin-left: 0 !important;
  }
}

@media only screen and (max-width: 575px) {
  .bottom-wave:after {
    height: 85px;
  }

  .text-muted {
    width: 100% !important;
  }

  #calendar-pop .popup {
    font-size: 15px;
    max-width: 80% !important;
  }

  .search-input {
    margin-top: 10px !important;
  }

  .map-search-block input {
    margin-top: 0 !important;
  }

  .company-card .card-body h5 {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 611px) {
  .appo_table td:nth-child(2) {
    text-align: left;
  }
}

/* 
@media only screen and (max-width: 482px) {
  .custom_flex { */
/* 
@media only screen and (max-width: 365px) {
  .buisness-table tr th:nth-child(2) {
    width: ;
  }
} */
/* } */

@media only screen and (max-width: 767px) {
  body {
    background-color: white !important;
  }

  .buisness-table tr th:nth-child(3) {
    width: 30% !important;
  }

  .buisness-table tr th:first-child {
    width: 80% !important;
  }

  .buisness-table tr td:nth-child(3) {
    width: 30% !important;
  }

  .dashborad-your-buisness tr {
    display: flex !important;
  }

  .buisness-table .booking-item-row td {
    display: inline-block !important;
  }

  .buisness-table tr td:nth-child(2),
  .buisness-table tr th:nth-child(2) {
    line-height: 21px !important;
  }

  .buisness-table tr td:first-child {
    width: 80% !important;
    max-width: 100%;
  }

  /* .buisness-table tr td:nth-child(2){
   line-height: 20px !important;
  } */

  .future_listing_card .form-check-input {
    margin-top: 3px !important;
  }
}

@media only screen and (max-width: 862px) {
  .purchaseRadio {
    margin-top: 43px !important;
  }
}

@media only screen and (max-width: 924px) {
  .future_listing_card .refferal-btn {
    top: 47px !important;
    left: 0 !important;
  }

  .custome-card-body {
    margin-top: 80px;
  }
}

.edit-service {
  margin-left: 22px;
}

@media only screen and (max-width: 1039px) {
  .flex_column {
    display: flex;
    flex-direction: column;
  }

  .flex_column td {
    padding: 0 !important;
    border: 0 !important;
  }

  .upcom_date {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #ffab68 !important;
  }

  .flex_column td a {
    display: contents !important;
  }
}

.appo_his {
  justify-content: space-between;
  flex-direction: initial !important;
}

.appo_his .upcom_date {
  font-size: 14px !important;
  color: #333333 !important;
}

@media only screen and (max-width: 480px) {
  .featured-table {
    width: 600px !important;
  }

  .badge-success,
  .badge-warning {
    max-width: 56px;
    width: 100%;
  }
}

@media only screen and (max-width: 1043px) {
  .custom_table {
    width: 600px !important;
  }
}

.font-weight {
  font-weight: 700;
}

.nav-icon-size {
  font-size: 20px;
}

.notification-search a {
  position: absolute;
  right: 15px;
  top: 52%;
  transform: translateY(-50%);
  font-size: 16px;
}

.notification-search a i {
  font-size: 17px;
  color: #000;
}

.referral-popover {
  margin-top: 5px;
  background-color: black;
  color: #dee2e6;
  padding-left: 19px;
  padding-right: 19px;
  padding-top: -1px;
  padding-bottom: 0px;
  border-radius: 10px;
  max-width: fit-content;
}

.referral-popover p {
  padding-left: 5px;
  padding-right: 5px;
}

@media only screen and (max-width: 379px) {
  .buisness-hours {
    /* padding: 0 23px !important; */
  }

  /* .buisness-information {
  padding: 0 23px !important;
} */
}

@media only screen and (max-width: 861px) {
  .custome-card-body {
    margin-top: 40px;
  }
}

.in-center {
  text-align: center;
}

.add-business-hours {
  padding: 0 0px !important;
}

@media only screen and (max-width: 456px) {
  .htt_disc br {
    display: none;
  }
}

.social-icon-color {
  color: white;
}

.set-padding {
  padding: 25px;
}

.set-padding-label {
  padding: 32px;
}

.edit-service-remove-btn {
  padding: 0px;
  padding-left: 2px;
}

@media only screen and (max-width: 767px) {
  .set-margin {
    margin-top: -14px;
  }

  .calendar-email-box {
    padding-bottom: 55px;
    overflow: hidden;
  }

  .setting-overflow {
    overflow: scroll;
  }
}

.booking-detail-margin {
  margin-bottom: 20px;
}

.favorite-page .text-muted {
  float: none !important;
}

.fav_company_list .card-body {
  position: relative !important;
}

.fav_company_list .company-card .card-body .rounded-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 25px !important;
}

#main-content .upcoming-table tbody td {
  border-top: 0 !important;
}

#main-content .upcoming-table .card-header:first-child {
  border-bottom: 1px solid rgb(212, 212, 212) !important;
}

.neck_padd {
  padding: 0 15px;
}

.list_ul_custom {
  display: flex;
  flex-wrap: wrap;
  gap: 13px;
  padding-left: 15px !important;
}

.edit_service_form .bookonlinemodule-wrapper {
  margin: 10px 0px !important;
}

.edit_service_form .bookonline-button-wrapper {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.set-booking-align {
  margin-left: 14px;
}

.icon-margin {
  margin-top: -5 !important;
}

.buisness-table .booking-item-row .service_info {
  margin-bottom: 13px !important;
}

.signup-services {
  width: 49%;
  margin-bottom: 8px;
}

.service-calendar {
  display: flex;
  flex-direction: column;
}

.title {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 1.2;
  letter-spacing: 0.896px;
}

.subtitle {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.42px;
}

.form-title {
  color: #000;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.56px;
}

.form-subtitle {
  color: #000;
  text-align: right;
  font-family: Poppins;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.28px;
}

.business-dialog {
  display: flex;
  padding: 20px 40px 30px 40px;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 20px;
  background: #fff3e7;
  width: fit-content;
}

.progress-bar-outer {
  display: flex;
  gap: 10px;
}

.progress-baar {
  width: 33.33%;
  height: 6px;
  text-align: center;
  line-height: 6px;
  color: white;
}

.bg-gray {
  background: #d9d9d9;
}

.bg-aqua {
  background: #68d4ce;
}

.bg-orange {
  background: #ffad68;
}

.calendar-btn {
  display: flex;
  padding: 6px 12px;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  cursor: pointer;
}

.gap-1 {
  gap: 10px;
}

.gap {
  gap: 20px;
}

.text-grey {
  color: #4e4e4e;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.45px;
}

.hours-outer {
  border-radius: 10px;
  border: 1px solid #ddd;
  background: #fff;
  padding: 10px;
}

.border-10 {
  border-radius: 10px !important;
}

.skip-text {
  color: #4e4e4e;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.36px;
}

.hours-grid-container {
  display: flex;
  align-items: center;
  /* grid-template-columns: auto auto auto; */
  /* background-color: #2196f3; */
  /* padding: 6px; */
  width: 100%;
}

.hours-grid-container-section-1 {
  display: flex;
  width: 55%;
  align-items: center;
  justify-content: space-between;
  padding-top: 3px;
}

.hours-grid-container-section-2 {
  /* width: 50%; */
  /* background-color: red; */
}

.hours-grid-container-switch {
  margin-top: 10px;
  width: 50px !important;
}

.hours-grid-container-section-2>.grid-col .grid-item:after {
  content: "";
  font-size: 12px;
  color: #707070;
  line-height: 18px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -6px;
  background: yellow;
}

.hours-grid-container-section-2>.grid-col {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.hours-grid-container-section-2>.grid-col .grid-item {
  width: 50%;
  padding: 0 5px;
  position: relative;
  background: none;
}

.hours-grid-container-section-2>.grid-col .grid-item select {
  font-size: 12px;
  line-height: 18px;
  color: #7c7c7c;
  height: 43px;
  width: 111px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(../src/images/arrow-down-sign-to-navigate.png);
  background-repeat: no-repeat;
  background-position: right 20px center;
  background-size: 10px;
}

.custom-select-container {
  position: relative;
  display: inline-block;
}

.select-box {
  border: 2px solid #000;
  border-radius: 6px;
  padding: 8px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;
  font-size: 18px;
  font-weight: 600;
}

.select-box.open .arrow {
  transform: rotate(180deg);
}

.select-box.open {
  background: #dff9f8;
}

.arrow {
  margin-left: 8px;
  filter: none !important;
}

.options-container {
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  background-color: #fff;
  border: 2px solid #000;
  border-radius: 5px;
  min-width: 300px;
  max-height: 150px;
  overflow-y: auto;
  z-index: 1;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
}

.option {
  padding: 8px;
  cursor: pointer;
}

.option:hover {
  background: #dff9f8;
}

.selected-one {
  background: #68d4ce;
}

.auto-complete{
  width: 100% !important;
}

.services-name{
  background-color: #ffad68 !important;
}