.mobile-menu-wrapper{
    position: relative;
    z-index: 3;
    width:100%;
}

.mobile-dropdown-button{
    width: 100%;
}

.dropdown-menu {
    z-index: 1050; /* Adjust this value as needed */
    background-color: #ffecd2;
    border: 1px solid #000;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    width: 100%;
  }
  
  .dropdown-item {
    font-weight: 600;
    color: #000;
    padding: 0.75rem 1rem;
    transition: background-color 0.2s ease;
  }

  .mobile-dropdown-button{
    font-weight: 600!important;
  }
  
  .dropdown-item:hover {
    background-color: #ffe4b5;
  }
  
  .dropdown-toggle {
    background-color: #ffecd2;
    border: 1px solid #000;
    border-radius: 0.5rem;
    font-weight: 600;
    color: #000;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }
  
  .dropdown-toggle:focus {
    outline: none;
    box-shadow: none;
  }
  
  .dropdown-menu.show {
    display: block;
  }