@media (max-width: 768px) {
  .edit-service {
    display: flex!important;
    flex-direction: row;
    padding-top: 2rem;
    margin-left: 20px!important;
    padding-left: 20px!important;
  }

  .mobile-top-arrow.edit-service-top-arrow {
    position: static!important;
  }

  .registration-page.edit-service-registration-page{
    padding-top: 2rem!important;
    padding-bottom: 6rem!important;
  }
  .registration-page.edit-service-registration-page form.form.list-group.edit_service_form.w-75.mx-auto{
    width: 100%!important;
    
  }

  .registration-page.edit-service-registration-page form textarea{
    min-height: 200px;
  }

  .w-50.bg-lightpeach.py-20.px-40.border-20.mb-2{
    width: 90%!important;
  }

  
}