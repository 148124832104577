/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.pain-scale-slider .slider {
  width: 92% !important;
  left: 15px;
  right: 15px;
}

.req-chk input {
  width: 16px;
  height: 16px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.list-group.list-group-flush.border-bottom {
  border-bottom: 3px !important;
  margin-bottom: 3px !important;
}
button.gm-control-active.gm-fullscreen-control {
  display: none;
}
input[type="text"] {
  text-overflow: ellipsis;
}

.floatright {
  float: right;
}

.plusbtnclass {
  margin-left: 250px;
  margin-top: -27px;
  position: "absolute";
}

.listComp {
  display: flex;
}

.requiredInput .form-check-input {
  margin-left: 5.75rem;
}

.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}

.overflow {
  overflow: auto;
}
.booking-date.badge {
  text-transform: capitalize;
}
.errorMsg {
  color: red;
}

/* .input-group:not(.has-validation) > .form-control:not(:last-child) {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
} */

.search-filter-bar .fas.fa-close {
  position: absolute;
  right: 20px;
  /* top: 50%; */
  transform: translateY(-50%);
  z-index: 999;
}

.grid-container {
  display: inline-grid;
  grid-template-columns: auto auto auto;
  /* background-color: #2196f3; */
  padding: 6px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  padding: 20px;
  font-size: 30px;
  text-align: center;
  /* margin-left: 25px; */
}

.active-badge {
  margin-bottom: 8%;
}
.activate-table {
  display: block;
}

.purchaseBtn:disabled {
  cursor: unset;
}

.modal-backdrop.show {
  opacity: 0.1 !important;
}

.starComp {
  color: gold;
  margin-left: 10px;
}

.purchaseRadio {
  display: flex;
  flex-wrap: wrap;
}

.purchaseBtn:disabled:hover {
  background-color: #007bff;
  border-color: #007bff;
}
.online-fields input::placeholder {
  text-overflow: ellipsis;
}

.disable-claim-btn {
  background: grey;
  color: white;
  border: 2px solid grey;
  border-radius: 10px;
  font-size: 15px;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.reject-button {
  margin-left: 10px;
}

.claim-btn {
  background-color: transparent;
  color: #ffd468;
  /* text-decoration: underline; */
  border: 2px solid #ffd468;
  padding: 5px 10px;
  border-radius: 10px;
  /* background-color: yellow; */
  font-size: 15px;
}

.claim-btn:hover {
  color: white;
  background-color: #ffd468;
}

.disabledBtn:hover {
  background-color: #007bff !important;
  border-color: #007bff !important;
}

/* pagination */

.pagination > li > a {
  padding: 8px 10px;
}

.page-item:first-child .page-link {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
  border-color: #27d7cf;
}

.page-item:last-child .page-link {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-color: #27d7cf;
}

.page-item.active .page-link {
  background-color: #27d7cf;
  border-color: #27d7cf;
}
/* for hover marker */

.hoverClass {
  border: 1px solid #27d7cf;
}

.markerHover {
  width: 65px !important;
  height: 65px !important;
}

select {
  width: 100px;
  text-overflow: ellipsis;
}

.intake-img-preview {
  margin-top: 10px;
}

.marginLeft {
  margin-left: 1rem;
}

.search-input {
  text-overflow: ellipsis;
}
.company-list {
  height: calc(100vh - 100px);
  overflow-y: auto;
}
.revese-in {
  display: flex;
  width: 100%;
}

.input-box i {
  position: absolute;
  right: 28px;
  top: 50%;
  transform: translateY(-50%);
}

.field-type {
  margin-bottom: 10px;
}

.online-field .form-check-input {
  margin-left: 0;
  width: auto;
}

.form-check-input {
  margin-top: 4px !important;
}

.booking-notes a {
  position: relative;
}
.booking-notes a .badge-light {
  position: absolute;
  top: -7px;
}
/* .map-search-block {
  margin-top: 40px;
} */
.gm-style {
  top: 27px !important;
}

.intake-tbl .bookonlinemodule-wrapper {
  margin: 0 !important;
  background: transparent;
  border: none;
}
.intake-tbl .bookonlinemodule-wrapper p {
  margin-bottom: 0;
}
.intake-tbl .list-group-item {
  background: transparent;
  padding: 5px 15px;
}
.intake-tbl .bookonline-header {
  background: transparent !important;
  color: #27d7cf;
  font-weight: bold;
}
.intake-tbl .online-fields .form-check {
  display: inline-block;
  width: 48%;
}
.intake-tbl .online-fields .form-check:last-child {
  margin-right: 0;
}
.intake-tbl .online-fields ul {
  padding-left: 0;
  display: flex;
  flex-wrap: wrap;
}
.intake-tbl table tr td:first-child {
  width: calc(100% - 150px);
}
.intake-tbl table tr td:nth-child(2) {
  width: 120px;
}

.status {
  width: 62px;
}

.margin-bottom {
  margin-bottom: -26px;
}

@media only screen and (max-width: 1440px) {
  .online-fields .company-checkbox {
    column-count: 1 !important;
  }

  /* .marginLeft {
    margin-left: unset;
  } */

  .online-fields .company-checkbox {
    padding-left: 0;
  }

  .image-preview {
    margin-top: 10px;
  }

  .set-align-edit-service {
    margin-left: -15px;
  }
}

@media only screen and (max-width: 1199px) {
  .appointment-tbl {
    /* overflow-y: scroll; */
    margin-bottom: 24px;
  }

  .set-align {
    margin-left: -13px;
  }

  .set-social-icon {
    margin-left: -8px !important;
    margin-top: 18px !important;
  }

  .contact-info {
    font-size: 12px;
  }

  .upload-intake-btn {
    height: unset !important;
  }

  .list-group-item button {
    height: 30px;
  }

  .list-group-flush .list-group-item {
    padding: 10px 10px;
  }

  .card-header {
    display: flex;
    align-items: flex-start;
  }

  .card-header h4 {
    margin-bottom: 0;
  }
  .intake-tbl .online-fields .form-check {
    display: inline-block;
    width: 100%;
  }
  .intake-tbl .bookonline-header {
    padding: 10px !important;
  }
  .featured-table {
    width: 900px !important;
  }
  .status {
    width: 62px;
  }
}

@media only screen and (max-width: 991px) {
  .floatright {
    float: unset;
    margin-top: 5px;
  }

  .listComp {
    display: block;
  }

  /* #change-log {
    display: none;
  } */

  .reject-button {
    margin-left: unset;
    margin-top: 10px;
  }

  h5 {
    font-size: 1.35rem;
  }

  .from-sent {
    font-size: 1rem;
    margin-left: 21px;
    margin-top: 12px;
  }

  .card-title {
    /* margin-left: 20px; */
    /* margin-top: 17px; */
    color: #fac57d;
  }
  .list-group-item h5 {
    margin-right: 10px;
  }

  #companyListCol .badge-pill {
    /* white-space: break-spaces; */
    text-align: left;
  }

  .removeImage {
    width: 30px;
    height: 30px;
  }

  .intake-tbl table tr td:first-child {
    width: 100%;
  }
  .intake-tbl table tr td {
    display: block;
    width: 100%;
  }
  .intake-tbl table tr td:nth-child(2) {
    width: 100%;
  }
  .intake-tbl table tr td button {
    margin-right: 10px;
    margin-top: 0 !important ;
  }
  .intake-tbl table tr td button:last-child {
    margin-right: 0;
  }

  .white_bg {
    background-color: white !important;
  }
  .map-search-block {
    margin-top: 25px;
    padding: 10px 0;
    margin-left: 0;
  }

  .set-company-label {
    margin-top: 56px;
  }
}

@media only screen and (max-width: 767px) {
  .purchaseRadio {
    display: unset;
  }
  .margin-bottom {
    margin-bottom: 0px;
  }

  /* #signup_header {
    margin-left: "-13px";
  } */

  .personal-calendar-email {
    margin-left: 10px;
    margin-top: -22px;
  }

  .card-footer {
    display: flex;
    margin-top: 39px !important;
    align-items: center;
    justify-content: center;
  }

  .list-group-item .justify-content-between {
    margin-bottom: 5px;
    align-items: center;
  }

  .list-group-item .justify-content-between h5 {
    margin-bottom: 0 !important;
  }

  .card-header {
    margin-bottom: 10px;
  }

  #upcoming-appointments .card-header {
    flex-wrap: wrap;
  }
  #upcoming-appointments .card-header > button {
    flex: 0 0 100%;
    margin-bottom: 15px;
  }

  #mapSearchCol {
    margin-bottom: 63px;
  }
  .fnd-srch-in {
    margin-bottom: 50px;
  }

  .btm-col-in {
    max-height: initial !important;
    overflow: hidden !important;
    overflow-y: scroll !important;
    margin-bottom: 70px;
  }
  .image-preview {
    margin-top: 20px;
  }

  .attached-comment {
    height: auto !important;
  }
  .map-search-block {
    margin: 10px 0;
  }
  .gm-style {
    top: 0px !important;
  }

  .company-list {
    height: auto;
  }
  .company-list .h-100 {
    height: auto !important;
    min-height: initial !important;
  }
  .company-listin-outer {
    min-height: initial !important;
  }

  .revese-in {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  #MapContainer {
    position: relative !important;
  }
  #companyListCol {
    height: 300px;
    overflow: hidden;
    overflow-y: scroll;
  }
  .intake-tbl table {
    width: 100%;
  }

  .card-header:first-child h4 {
    font-size: 20px !important;
    font-weight: 600 !important;
  }

  .featured-table thead tr th:first-child {
    padding-left: 0 !important;
  }

  .no_upcomming {
    margin-left: 10px !important;
    /* text-align: left !important; */
    position: relative;
    float: left;
  }

  .user-icon i {
    font-size: 30px;
  }

  .margin-top {
    margin-top: -50px;
  }

  .fav_company_list {
    max-height: inherit;
    margin-bottom: 60px;
  }

  .favorite-page .text-muted {
    margin-right: 20px;
    margin-top: 0;
  }

  .buisness-manager-page .mobile-top-arrow {
    top: 15px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
  }
}

@media only screen and (max-width: 576px) {
  .claim-btn {
    padding: 2px 2px;
    margin-left: 10px;
  }

  .pagination {
    font-size: 10px;
  }

  .cancel-paddingbtm {
    padding-bottom: 60px !important;
  }

  .edit-company-btn {
    font-size: 11px;
    width: 119px;
  }

  .h2,
  h2 {
    font-size: 1.5rem;
  }
  .mr-in {
    margin-right: 10px !important;
  }

  #main-content {
    width: 100%;
    margin-left: 0;
    padding-left: 0;
  }

  .landing {
    padding: 1.5rem;
  }
  #provide-feedback span {
    display: none;
  }

  .mob-massage {
    margin-bottom: 60px;
  }

  .mob-massage .list-group {
    max-height: 370px !important;
  }

  .card-header:first-child,
  .card-header {
    padding: 15px 20px 0;
  }

  .intake-tbl {
    overflow-y: scroll;
  }
  .intake-tbl table {
    width: 600px;
  }

  .msg-list,
  #inbox,
  #add-service,
  #appointments {
    margin-bottom: 70px;
  }

  .online-fields .company-checkbox {
    column-count: 1 !important;
    padding-left: 0;
  }

  #mapSearchCol {
    margin-bottom: 20px;
  }
  #change-log span {
    display: none;
  }
  #change-log {
    /* right: 15px !important; */
    display: none;
  }
  #main-content {
    padding-top: 20px;
  }
  .online-fields .disabledt {
    color: #000;
  }
  .company-list {
    margin-bottom: 65px;
  }

  #mapSearchCol #MapContainer {
    min-height: inherit !important;
  }
  #companyListCol + #mapSearchCol > div:first-child {
    height: 230px !important;
  }

  #companyListCol {
    height: calc(100vh - 300px);
  }
  .intake-tbl table {
    width: 100%;
  }
  .intake-tbl .bookonline-header {
    margin-bottom: 0;
  }

  .card-body .content {
    font-size: 14px;
  }

  .signup-services {
    width: 100% !important;
    /* margin-bottom: 8px; */
  }
}

@media only screen and (max-width: 479px) {

  .nav{
    flex-wrap: nowrap;
  }

  .maxheight-400-auto{
    max-height: unset !important;
    overflow: visible !important;
  }

  .upcomming-tbl {
    width: fit-content !important;
  }
  .pagination > li > a {
    padding: 8px 10px;
  }
  .cancel-paddingbtm {
    padding-bottom: 40px !important;
  }

  .h2,
  h2 {
    font-size: 1.2rem;
  }
  body {
    font-size: 0.8rem;
  }

  h1 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.3rem;
  }
  h5 {
    font-size: 1rem;
    line-height: 1.5;
  }
  .landing form {
    margin-bottom: 10px;
  }
  #sidebar-nav {
    /* height: auto; */
    padding: 0
  }

  /* #sidebar-nav.open {
    height: 4rem;
  }

  #sidebar-nav.close {
    height: 4rem;
  } */


  #sidebar-nav a {
    padding: 5px 5px;
  }
  .list-group-item {
    padding: 20px 20px;
  }
  .mob-massage {
    margin-bottom: 50px;
  }
  .card .card-body .card:last-child {
    margin-bottom: 0;
  }
  .card-header:first-child,
  .card-header {
    padding: 15px 15px 15px;
  }
  /* .card .card-body {
    padding: 15px 15px;
  } */
  #upcoming-appointments .card-header h4 {
    flex: 0 0 70%;
  }
  .office-avl-tbl,
  /* .appointment-tbl, */
  .upcomming-tbl {
    overflow-y: scroll;
  }

  .tbl-noscroll {
    overflow: inherit;
  }

  .office-avl-tbl table,
  .upcomming-tbl table {
    width: 600px;
  }
  /* .appointment-tbl table {
    width: 400px;
  } */
  #inbox,
  #settings,
  #add-service,
  #appointments {
    margin-bottom: -3px;
  }
  #sidebar-nav {
    z-index: 9999;
  }
  #mapSearchCol {
    margin-bottom: 10px;
  }
  .fnd-srch-in {
    margin-bottom: 30px;
  }
  .msg-list {
    margin-bottom: 50px;
  }

  .online-fields .form-check {
    padding-left: 20px;
  }

  #mapSearchCol #MapContainer {
    min-height: 100% !important;
  }

  .online-fields .form-check-input {
    width: 90%;
    padding: 0 15px;
    /* top: -2px; */
  }

  #companyListCol + #mapSearchCol > div:first-child {
    height: 300px !important;
  }
  #companyListCol {
    height: calc(100vh - 400px);
  }

  .card-footer .btn-lg {
    font-size: 16px;
  }
  #add-service .list-group-item label {
    position: relative;
    top: -3px;
  }
  .form-comment .attached-comment {
    right: 10px;
  }
  .form-outer {
    padding-bottom: 30px;
  }
  .list-group-flush > .list-group-item {
    font-size: 16px;
    padding: 8px 10px;
  }
  .company-list {
    margin-bottom: 50px;
  }

  /* .company-title {
    width: 55%;
  } */

  .requiredInput .form-check-input {
    margin-left: 4.75rem;
    margin-top: 0.2rem;
  }
}

/* body {
  font-family: Arial, sans-serif;

  background-size: cover;
  height: 100vh;
} */

/* h1 {
  text-align: center;
  font-family: Tahoma, Arial, sans-serif;
  color: #06d85f;
  margin: 80px 0;
} */

/* .box {
  width: 25%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.2);
  padding: 35px;
  border: 2px solid #fff;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
} */
/* 
.button {
  font-size: 1em;
  padding: 10px;
  color: #fff;
  border: 2px solid #06d85f;
  border-radius: 20px/50px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
}
.button:hover {
  background: #06d85f;
} */

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.overlay:target {
  visibility: visible;
  opacity: 1;
}

.business-info {
  margin-left: 78px;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.delete_pop_overlay .popup {
  margin: 107px auto !important;
}

.popup {
  margin: 0 auto !important;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 15%;
  position: relative;
  /* transition: all 5s ease-in-out; */
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}
.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  /* transition: all 200ms; */
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}
.popup .close:hover {
  color: #06d85f;
}
.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }
  .popup {
    width: 70%;
  }
}

.refferal-btn {
  position: absolute;
  top: 8px;
  right: 0px;
}

.new-feature-list .refferal-btn {
  position: static;
}



@media screen and (max-width: 767px) {
  .new-feature-list button {
    margin-top: 10px !important;
    margin-bottom: 10px;
  }
  .new-feature-list .card-body .card-header {
    display: block !important;
  }
  .new-feature-list .card-header .refferal-btn {
    position: static;
  }
  /* .table-border{
    border: 1px solid gray;
    border-radius: 10px;
  } */
}

.label {
  margin-right: 70%;
  position: "absolute";
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.popup_custom {
  width: 100% !important;
  max-width: 40% !important;
}

.popup_custom p {
  font-weight: 500;
}

.pop_cancle {
  border: 1px solid #25d2c3;
  padding: 11px 46px;
  border-radius: 10px;
  margin-right: 21px;
}

.top {
  margin-top: 0 !important;
}

@media only screen and (max-width: 767px) {
  .delete_pop_overlay {
    padding: 0 24px;
  }

  .popup_custom {
    width: 100% !important;
    max-width: 100% !important;
  }

  .popup_custom a:first-child {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    right: 0;
    top: 0;
    width: 100%;
    margin-bottom: 40px;
  }

  .calendar-email {
    margin-left: 9px;
  }
}

@media only screen and (max-width: 400px) {
  .popup_custom p {
    font-size: 14px;
  }

  .top {
    padding: 0 26px !important;
  }
}

@media only screen and (max-width: 421px) {
  .pop_cancle {
    margin-right: 15px;
    /* padding: 11px 17px !important; */
    font-size: 12px !important;
  }
}

/* --- */

.sigin_logo {
  visibility: hidden;
}

#email,
#password {
  padding: 25px 0.75rem !important;
}

.submit_btn {
  margin-bottom: 19px;
  padding: 12px 112px;
}

.send_btn {
  margin-bottom: 54px;
  padding: 12px !important;
}

.signup_btn a {
  display: block;
  margin-top: 15px;
  width: fit-content;
  margin: 15px auto 0;
  color: #ffab68;
  padding: 11px 52px;
  border-radius: 10px;

  border: 2px solid #ffab68;
}

.user-icon {
  visibility: unset;
}

.your_bussi_title {
  visibility: hidden;
  position: absolute;
  font-size: 20px;
  color: #25d2c3;
  font-weight: 400;
}

.mid_hed {
  visibility: hidden;
  position: absolute;
  text-align: center;
  font-size: 28px;
  color: #ffab68;
  font-weight: 800;
}

.arrow_icon_bg {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  background-color: #25d2c3;
}

.arrow {
  width: 18px;
  filter: brightness(1) invert(1);
}

.main_search {
  padding: 0 1.25rem;
}

.flex {
  display: flex;
  align-items: flex-end;
}

.upcoom_event {
  visibility: hidden;
}

/* .calender_container {
  padding: ;
} */

.google_calender,
.calendly {
  display: flex;
  align-items: center;
  /* padding: 29px 22px; */
  justify-content: space-between;
}

.calendly {
  background-color: #f5f5f5;
  border-radius: 0 0 15px 15px;
}

.google_calender p,
.calendly p {
  margin-bottom: 0 !important;
  font-size: 18px;
}

.calender_icon {
  font-size: 27px;
  color: #00d330;
}

.position {
  float: right;
  margin-top: 27px;
  position: relative;
}

.add_btn {
  font-size: 16px;
}

.card-body .signout_content {
  margin-top: 25px;
}

.edit_del_btn a i {
  font-size: 20px;
}

.edit_del_btn .fa-trash-can {
  color: red !important;
}

.edit_service_form .list-group-item {
  margin-bottom: 10px !important;
  padding: 0;
}

.edit_service_form .list-group-item input {
  font-size: 12px;
}

.edit_service_form .list-group-item textarea {
  font-size: 12px;
  height: 60px;
  padding: 14px 12px;
}

.edit_service_form .list-group-item select {
  font-size: 12px;
  padding: 23px 12px;
}

.edit_service_main_container {
  border: none;
}

.methode {
  display: flex;
  align-items: center;
}

.methode p {
  margin-bottom: 0;
  font-size: 15px;
  margin-right: 10px;
}

.list-group-item {
  border: 0;
}

.contact_method_main_container {
  padding: 40px 0 15px 0;
}

.book_online {
  display: flex;
  align-items: center;
}

.book_online label {
  margin-bottom: 0;
  margin-left: 5px;
}

.google_map {
  margin-left: 0 !important;
}

.map_main_container {
  /* height: calc(100vh - 100px) !important; */
}

.user i {
  top: 29px;
  left: 33px;
  font-size: 28px;
  position: relative;
  color: #ffab68;
}

.title_map h3 {
  color: #ffab68;
  font-size: 28px;
  left: 31px;
  font-size: 27px;
  position: relative;
  top: 40px;
}
.fav_user i {
  left: 12px !important;
  cursor: pointer;
}

.favorite_hed h4 {
  margin-left: 10px;
  font-size: 28px !important;
  color: #ffab68 !important;
  margin-bottom: 30px;
}

.favorite_bg {
  margin-top: 40px;
}

.calender_pop_btn_container {
  display: flex;
  flex-direction: column;
}

.calender_pop_btn_container input {
  margin-top: 27px !important;
}

.register_fom_container input {
  padding: 23px 18px;
  font-size: 12px;
}

.register_btn {
  padding: 14px 100px !important;
}

.register_hed {
  margin: 13px 30px !important;
}

.register_fom_container h1 {
  color: #ffab68;
}

.pay_In_btn {
  width: 100%;
  background-color: #ffab68 !important;
  max-width: 27%;
}

.max_width {
  width: 100%;
  max-width: fit-content;
  padding: 7px 30px;
  border-radius: 5px !important;
}

/* .max_width  i {
  color: white !important;
} */

.buisness_user_icon {
  position: absolute;
  right: 82px;
  /* width: 20px; */
  z-index: 999;
  top: 40px;
}

.buisness_user_icon img {
  height: 25px;
  cursor: pointer;
}

.table thead th {
  border: 0 !important;
}

.appointment-tbl tr {
  /* border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6; */
}

.custom_check input {
  height: 16px !important;
  width: 16px;
  position: relative;
  top: 1px;
  margin-right: 5px;
}

.custom_card {
  border-radius: 10px !important;
}

.bookonline-header_custom {
  padding: 30px 20px !important;
}

.deleteBtn_custom {
  width: 60px !important;
  border-radius: 10px !important;
}

.set_timezone h4 {
  font-size: 12px;
  padding-left: 5px;
  margin-top: 16px;
  margin-bottom: 8px;
}

#set_timezone_opt {
  width: 100%;
  margin-left: 5px;
  font-size: 12px;
  padding: 11px 3px;
  border-radius: 0.5rem;
  border: 1px solid #ced4da;
  outline: 0;
  margin-bottom: 24px;
}

.box-header {
  background: url(../src/images/wave-shape.png) no-repeat;
  background-size: 100% 100%;
}

.favriot a {
  color: #25d2c3 !important;
  font: normal normal 600 18px/22px Poppins;
}

.favourite a {
  color: #ffab68 !important;
  font: normal normal 600 18px/22px Poppins;
}

.box-header .favriot a {
  color: #fff !important;
  font: normal normal 600 18px/22px Poppins;
}

.company-list {
  margin: 0 10px;
  padding: 18px 5px;
  border-radius: 10px;
  border: 1px solid #d8d8d8;
}

/* .company-card {
  background-color: #D8D8D8 !important;
} */

.favorite_bg .card {
  border: none;
  border-radius: 0 !important;
}

.fav_company_list .card {
  border: none;
  margin-bottom: 0 !important;
}

.grey:nth-child(even) {
  background-color: #f8f8f8 !important;
}
.future_listing_card .card-header {
  padding: 13px 13px 13px 0 !important;
}

#calendar-pop .popup {
  width: 100%;
  max-width: 30% !important;
}

#calendar-pop .popup .calender_pop_btn_container input {
  width: 100%;
  max-width: 301px !important;
}
/* .appo_table td {
  width: 100%;
  max-width: calc(100% / 3);
} */

/* .appo_table td:nth-child(2) { 
  text-align: center;
 } */

.appo_table td:nth-child(3) {
  text-align: right;
}

.appo_table td a {
  color: #000 !important;
}

.appo_table td i {
  color: black !important;
}

.app_history_icon a i {
  color: #27d7cf !important;
}

.bold {
  font-weight: 500;
}

.clear_btn {
  width: 100%;
}

.clear_btn a {
  position: relative;
  float: right;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #27d7cf;
}

.padding {
  padding: 0 16px;
}

.success_popup_items {
  text-align: center;
  /* height: 600px; */
}

.success_popup_items h4 {
  font-size: 24px;
  font-weight: 500;
  color: #ffab68;
  margin-bottom: 22px;
  margin-top: 6px;
}

.success_popup_items p {
  font-size: 12px;
}

.success_popup_items button {
  margin: 0 auto;
}

.success_popup_items .button {
  text-align: center;
}

.success_popup_items .button button {
  padding: 11px 45px !important;
}

.search_zoom {
  background-color: white;
  padding: 7px 12px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.search_zoom i {
  font-size: 20px;
}

.google_map .user {
  visibility: hidden;
}

.edit_service_form {
  padding: 12px !important;
}

.feature_main_box .inner {
  padding: 17px !important;
}

.inActive_plane .inner {
  padding: 17px !important;
}

.signup-link a {
  font-weight: 500 !important;
}

.register-btm p a {
  font-weight: 700 !important;
}

.add_ser_pop {
  /* height: fit-content !important; */
  overflow-x: scroll !important;
  padding: 70px 0 54px 0 !important;
  /* padding-left: 0 !important;
  padding-right: 0 !important; */
}

.add_ser_popup_form {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.add_ser_pop h2 {
  padding-left: 28px !important;
  padding-right: 28px !important;
  margin-bottom: 26px !important;
  font-size: 14px !important;
}

.add_ser_pop small {
  padding-left: 28px !important;
  padding-right: 28px !important;
  font-size: 10px !important;
  margin-bottom: 6px !important;
}

.add_ser_popup_form .form-group {
  padding: 0 !important;
}

.add_ser_popup_form .form-group input {
  padding: 14px 12px !important;
}

.add_ser_popup_form .form-group textarea {
  height: 106px;
  padding: 14px 12px !important;
}

.add_ser_contact_method {
  padding-left: 28px !important;
  padding-right: 28px !important;
}

.switch_add_ser {
  padding-left: 38px !important;
  padding-right: 38px !important;
}

.ser_pop_create_intake_form {
  background-color: #f5f5f5;
  padding: 13px 38px;
  margin-bottom: 12px;
}

.ser_pop_create_intake_form span {
  margin-bottom: 8px;
}

.ser_pop_create_intake_form a {
  font-size: 14px;
}

.ser_pop_create_intake_form a {
  display: table;
  padding: 12px 24px;
  border-radius: 10px;
  border: 4px solid #25d2c3;
  cursor: pointer;
  font-weight: 500;
}

.switch_add_ser label {
  font-size: 12px !important;
}

.add_ser_footr_pop_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 55px 0 0 0;
}

.intake_add_btn {
  right: 10px;
  position: absolute;
}

.intake_checkbox_add_ser {
  height: fit-content !important;
}

.bottom {
  margin-top: 20px;
}

.btn-secondary {
  position: relative !important;
  z-index: auto !important;
}

.future_listing_card .card-body {
  padding: 0 !important;
}

.feature_main_box .card-header {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.feature_main_box .card-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.future_listing_card .DemoWrapper {
  margin: 0 !important;
  padding: 0 !important;
  margin-top: 15px !important;
}

.check_container {
  height: 106px;
  width: 106px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 10px;
  border: 3px solid #ffa264;
}

.check_container i {
  color: #ffa264;
  font-size: 70px;
}
.auto-complete .form-group {
  max-width: 100% !important;
  width: 100% !important;
  margin-left: 0 !important;
  margin-top: 10px !important;
}

.map-location {
  margin-bottom: 15px;
  /* display: flex;
  flex-wrap: wrap; */
  width: 100%;
  max-width: 100%;
}

.auto-complete .search-input {
  box-shadow: none !important;
}

.signup-link a {
  font-weight: 500 !important;
}
.register-btm p a {
  font-weight: 700 !important;
}

.business-form-btn {
  background: none;
  border: 0;
  color: #61676d;
}

.plus-sign {
  /* margin: 5px; */
  position: relative;
}
.hide {
  visibility: hidden;
}

.up_coming {
  border-bottom: none !important;
}

.add-services {
  padding: 0 52px 22px !important;
}

.notification-table tr td:first-child {
  max-width: 139px !important;
}

.notification-table tr td:nth-child(3) {
  max-width: fit-content !important;
}

.mar_top {
  margin-top: 0 !important;
}

.http_field {
  padding: 22px 8px !important;
}

.htt_disc {
  font-size: 12px;
  padding-left: 17px;
}

.http_bg {
  padding: 13px;
  background-color: #d8d8d836;
  width: 100%;
}

.http_btn {
  text-align: center;
}

.bg-dark {
  background-color: #343a40 !important;
  padding-right: 0 !important;
}
*/ .user-icon i {
  color: #ffa264;
}

.intake_req {
  margin-top: 8px;
}

.edit-service button {
  outline: 0;
  border: 0;
  position: absolute;
  left: 16px;
  top: 23px;
  background-color: transparent;
}

.edit-service button i {
  font-size: 19px;
  color: #25d2c3 !important;
}

.edit_form_textarea {
  height: 100px !important;
}

.service_info {
  font-size: 14px;
}

.service_info p {
  margin: 3px 0 !important;
  margin-bottom: 0 !important;
}

.service_info p i {
  margin-right: 5px;
}

.service_info p a {
  color: #25d2c3 !important;
  text-decoration: underline;
}

.upcom_date {
  margin-right: 7px;
}

.service_info i {
  display: inline-flex;
}

.appo_his {
  margin-bottom: 0 !important;
}

#appointment-history .table {
  margin-bottom: 0 !important;
}

#appointment-history .appointment-tbl {
  margin-bottom: 0 !important;
}

.ser {
  display: block !important;
}

.range_container {
  margin: 15px 0;
}

.ser_pop_create_intake_form a {
  margin-top: 6px;
  color: #25d2c3 !important;
}

.margin-top-pop {
  margin-top: 60px !important;
}

.eye_icon {
  height: 30px;
  width: 23px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: rgb(255, 255, 255);
}

.create_btn {
  margin-left: 172px;
}

.flex_column {
  flex-direction: column !important;
}

.custom_class {
  display: flex;
  align-items: center;
  margin-left: 0 !important;
}

.badge-success,
.badge-warning {
  max-width: 80px;
  width: 100%;
}

.user_num {
  width: 200px !important;
}

.side_gap {
  position: absolute;
  right: -27px !important;
}

.company-list .badge {
  width: fit-content;
  padding: 0 8px !important;
}

.toast {
  z-index: 9999 !important;
  position: absolute;
}

.bg_bottom_signup {
  position: absolute;
}

input[type="range"] + datalist option {
  font-size: 22px;
  margin-top: 18px;
  text-align: left;
  margin-left: 0 !important;
}

input[type="range"] + datalist {
  margin-left: 11px !important;
}

.edit_comp_heading {
  display: flex;
}

.edit_header {
  display: flex;
  align-items: center !important;
}

.edit_header h4 {
  margin-bottom: 0 !important;
  margin-left: 20px !important;
}

.signup_header {
  margin-top: 30px;
}

#pop_martop {
  /* margin-top: 27px !important; */
}

@media only screen and (max-width: 479px) {
  .rounded-circle {
    width: 30px !important;
    height: 30px !important;
    padding: 0px !important;
    /* top: 0 !important; */
    left: 0 !important;
    z-index: 9;
    color: #fff;
    background: #25d2c3;
  }

  .header-bg {
    margin-bottom: 0;
  }

  .purchaseRadio .form-check {
    width: 100% !important;
  }

  .company-list .rounded-circle {
    left: auto !important;
    /* top: 10px !important; */
  }

  .favorite_bg {
    margin-top: 0;
  }
}

.personal-calendar-email {
  margin-left: 17px;
  margin-top: -22px;
  margin-bottom: 8px;
}

@media only screen and (max-width: 540px) {
  .set-signup-icon {
    margin-top: 37px;
  }
}

@media only screen and (max-width: 767px) {
  .booking-detail-page .row {
    width: 100% !important;
    margin: 0 !important;
  }
  .booking-detail-page .row .col-lg-4 {
    padding: 0 !important;
  }
  .booking-detail-page .col-sm-12 {
    padding: 0 !important;
  }

  .booking-detail-page .mobile-top-arrow {
    background: none;
    border: none;
    color: #25d2c3;
    top: 8px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    left: -15px !important;
  }

  .company-list .rounded-circle {
    top: 90px !important;
  }

  .spacing {
    margin-bottom: 0 !important;
  }
}

.business-service .popup {
  padding: 10px !important;
}
.business-service .bookonlinemodule-wrapper {
  margin: 10px 0px !important;
}
#edit-pop .popup {
  margin-top: 5% !important;
  padding: 20px 40px;
  background: #fff;
  border-radius: 5px;
  width: 30% !important;
  position: relative;
}

.disabledli {
  pointer-events: none;
  opacity: 0.7;
}

@media only screen and (min-width: 769px) {
  .min-w-678 {
    min-width: 678px !important;
  }
}

/* tablet and mobile */

/* mobile */

@media ( max-width: 768px){
  .w-585{
    width: 100% !important;
  }

  .text-mobile-sm{
    font-size: 20px !important;
    font-weight: 600 !important;
  }

  .hours-grid-container{
    flex-direction: column !important;
    align-items: stretch !important;
  }

  .hours-grid-container-section-1{
    width: 100% !important;
  }
  .hours-grid-container-section-1 .hours-grid-container-switch-container, .hours-grid-container-section-2 .hours-grid-container-switch-container{
    justify-content: end;
  }
  .hours-grid-container-section-2 .closing-time-dropdown-container{
    display: flex;
    justify-content: end;
  }
}
  

 
