.booking-card-header {
    background: #25d2c3;
    /* margin: -22px; */
    border-radius: 20px 20px 0 0;
    padding: 10px 10px 5px 10px;
    margin-bottom: 10px;
}

.booking-card{
    padding-left: 0px;
}

@media (max-width: 767px){
    .booking-detail-page .card-body {
        padding-top: 1.25rem!important;
    }
}




