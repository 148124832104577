select.drop-arrow.business-service-select {
    padding: .375rem .75rem;
  }


@media (max-width: 768px) {

textarea#service-description{
    min-height: 200px!important;
}

.add-service-container{
    padding-bottom: 5rem!important;
}


}