.wlm-container .modal-content{
    border-radius: 10px!important;
    background-color: #FFF3E7;
}

.wlm-header{
   border-bottom: none!important;
}

.wlm-body{
    
}

.wlm-footer{
    border-top: none!important;
}

.wlm-title{
    text-align: center!important;
    width: 100%
}

.wlm-prefered-time-container{
    border-radius: 25px;
    background-color: white;
    padding: 4px;
}

.wlm-prefered-time-container .time-icon{
    margin-right: 4px;
}

.wlm-container .modal-dialog {
    /* max-width: 600px;  */
  }
  

  

  /* .wlm-body {
    padding: 2rem;
  }
   */
  .wlm-time-slot {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .wlm-prefered-time-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .toggle-button {
    width: 30px;
    height: 30px;
    border-radius: 50%!important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modal-footer {
    justify-content: center;
  }