
.bookonline-header{
    background-color: rgba(252, 135, 135, 0.05) !important;
    display: block !important;
    padding: 10px 15px !important;
}



.bookonlinemodule-wrapper{
    margin: 10px;
    /* height: 180px !important; */
}


.bookonline-text{
    margin: 10px !important;
}

.online-fields input{
    padding: .375rem .75rem; 
    background-color: white;
    border-radius: 0.5rem;
    border: 1px solid #ced4da;
    width: 90%;
}

.online-fields input[type="checkbox"], .online-fields input[type="radio"]{
    width: auto;
}

.online-fields  .form-check-input{margin-left: -20px;}

.field-label{
    display: flex;
    flex-direction: column;
    text-transform: capitalize
}

.check-box-control{
    display: block;
    /* padding: .375rem .75rem; */
}

.bookonline-button-wrapper{
    padding: .75rem 1.25rem;
}

.deleteBtn{
    border-radius: 100% !important;
    height: 35px !important;
    width: 35px !important;
}

.field_list{
    border: none !important;
    border-bottom: 1px solid rgba(0,0,0,.125) !important;
}

li{
    list-style: none;
}

ul{
    column-count: 3;
}

.company-checkbox{
    column-count: 2 !important;
}

.form-check-input{
    margin-left: 5.75rem;
}

/* style range */
input[type=range] {
    width: 100%;
    max-width: 100%;
    margin-left: 0;
}

/* style datalist */
input[type=range] + datalist {
    display: flex;
    justify-content: space-around;
    margin-top: -4px;
}
input[type=range] + datalist option {
    display: inline-block;
    width: calc((100% - 12px) / (var(--list-length) - 1));
    text-align: center;
    font-size: 10px;
    margin-top: 10px;
}
input[type=range] + datalist option:first-child {
    width: calc((100% - 12px) / ((var(--list-length) - 1) * 2) + 6px);
    text-align: left;
    margin-left: -15px;
}
input[type=range] + datalist option:last-child {
    width: calc((100% - 12px) / ((var(--list-length) - 1) * 2) + 6px);
    text-align: right;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: black;
    margin-bottom: 16px;
    cursor: pointer;
  }

  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 5px;
    border-radius: 5px;
    background: #d3d3d3 !important;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
    padding: unset !important;
  }


  @media only screen and (max-width: 576px) {
      .requiredInput{
        margin-top: 0.3rem;
      }
    
      .requiredInput .form-check-input{
        margin-left: 5.75rem;
      }
  }
