#main-content.section-bg {
  background-size: 100% !important;
  background-position: right top -340px !important;
}
.top-header {
  text-align: right;
  padding: 1vw 4vw;
}
.top-header .search-bar,
.top-header .user-login,
.top-header .social-media {
  display: inline-block;
  vertical-align: middle;
}
.top-header .search-bar button {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  background: none;
  color: #27d7cf;
}
.top-header .search-bar button:hover {
  color: #ffad68;
}
.top-header .search-bar {
  position: relative;
  margin-right: 80px;
}
.top-header .search-bar input {
  border: none;
  border-bottom: 1px solid #000;
  width: 0;
  opacity: 0;
  visibility: hidden;
}
.top-header .search-bar.open input {
  width: 100%;
  opacity: 1;
  visibility: visible;
}
.top-header .user-login {
  color: #27d7cf;
}
.top-header .user-login i {
  margin-right: 10px;
}
.top-header .social-media {
  margin-bottom: 0;
  column-count: initial;
}
.top-header .social-media li {
  margin-right: 15px;
  display: inline-block;
}
.top-header .social-media li:last-child {
  margin-right: 0;
}

.bottom-header {
  display: flex;
  align-items: center;
  padding: 0 4vw;
}
.bottom-header a:hover {
  text-decoration: initial;
}
.main-logo img {
  max-width: 112px;
}
.home-content {
  padding: 0 15px;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-top: 120px;
  padding-bottom: 150px;
}
.home-content:after {
  content: "";
  display: block;
  clear: both;
}
.home-content-left {
  width: 46%;
  font-family: "Raleway", sans-serif;
}
.home-content-left h1 {
  color: #ffad68;
  font-size: 3rem;
  font-weight: 800;
  margin-bottom: 20px;
  font-family: "Poppins", sans-serif;
}
.home-content-left h2 {
  color: #ffad68;
  font-family: "Poppins", sans-serif;
  margin-bottom: 30px;
}
.home-content-left h2 em {
  font-weight: 400;
}
.home-content-left p {
  font-size: 20px;
  margin-bottom: 30px;
  line-height: 30px;
}
.home-content-right {
  width: 54%;
}
.home-content-right figure {
  max-width: 576px;
  float: right;
}
.home-content-right figure img {
  width: 100%;
}
.home-content-left a {
  font-size: 18px;
  display: inline-block;
  line-height: 1.2 !important;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  letter-spacing: 0em;
  border-radius: 0.5em;
  padding: 1em 1.8em;
  background: transparent;
  border: 1px solid #27d7cf;
  color: #27d7cf;
  box-shadow: 0 0em 0em rgb(0, 0, 0, 0.2);
  text-decoration: none;
}
.home-content-left a:hover {
  background: #27d7cf;
  color: #fff;
}
.bottom-header ul {
  column-count: initial;
}
.bottom-header ul li {
  display: inline-block;
  margin-right: 40px;
  font-family: "Raleway", sans-serif;
}
.bottom-header ul li:last-child {
  margin-right: 0;
}

.search-bar {
  position: relative;
}
.search-bar .locate-btn {
  background: #68d4ce;
  border-bottom-left-radius: 39px;
  border-top-left-radius: 39px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
  color: #fff;
  border: 1px solid black;
  width: 50px;
  height: 50px;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  line-height: 42px;
  font-size: 22px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  line-height: 42px !important;
}
.search-bar input {
  height: 50px;
  width: 100%;
  border-radius: 39px;
  border: 1px solid #000;
}
.search-bar .form-control {
  background: rgba(242, 243, 244, 0.5);
}
.search-bar button {
  background: #68d4ce;
  border-bottom-right-radius: 39px;
  border-top-right-radius: 39px;
  width: 68px;
  border: 1px solid #000;
  color: #000;
  height: 50px;
  position: absolute;
  right: 0%;
  top: 0%;
}

.search-bar-clear button {
  background: none;
  height: 50px;
  border: 1px solid #000;
  color: #27d7cf;
  position: absolute;
  right: 41px;
  top: 0;
  border-left: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.search-bar input:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid black;
}
.autocomplete-dropdown-container {
  position: absolute;
  width: 100%;
}
.autocomplete-dropdown-container > div {
  padding: 2px 10px;
}

@media only screen and (max-width: 1493px) {
  #main-content {
    background-position: right top -160px !important;
  }
}
@media only screen and (max-width: 1199px) {
  #main-content {
    background-position: left top !important;
  }
  .home-content-right figure {
    float: none;
    margin: 0 auto;
    max-width: 405px;
  }
  .home-content-left h1 {
    font-size: 2.3rem;
  }
  .home-content {
    margin-top: 90px;
    padding-bottom: 120px;
  }
  .home-content-left h2 {
    font-size: 1.5rem;
  }
  .home-content-left p {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 24px;
  }
  .home-content-left a {
    padding: 10px 20px;
    font-size: 16px;
  }
}

@media only screen and (max-width: 991px) {
  #main-content.section-bg {
    background-size: unset !important;
    /* background-position: right top -340px !important; */
  }
  .home-content {
    display: block;
  }
  .home-content-left {
    width: 100%;
    text-align: left;
  }
  .home-content-right {
    width: 100%;
    margin-top: 50px;
  }
  #main-content {
    background-repeat: no-repeat !important;
    background-position: left top 500px !important;
  }
  .home-content {
    margin-top: 60px;
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .home-content {
    margin-top: 40px;
    padding-bottom: 120px;
  }
}
@media only screen and (max-width: 479px) {
  .home-content-left h1 {
    font-size: 1.8rem;
  }
  .home-content-left h2 {
    font-size: 1.2rem;
  }
  .home-content-left p {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .home-content {
    padding-bottom: 90px;
  }
}
