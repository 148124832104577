.plan-card {
    margin-bottom: 10px;
    padding-left: 15px;
  }
  
  .plan-card-body .row .col-6 {
    padding: 5px;
  }
  
  .font-weight-bold {
    font-weight: bold;
  }
  
  .badge-warning {
    background-color: #ffc107;
    color: #fff;
  }
  
  .badge-success {
    background-color: #28a745;
    color: #fff;
  }
  
  .badge-danger {
    background-color: #dc3545;
    color: #fff;
  }
  
  .no-plans {
    text-align: center;
    padding: 20px;
    font-size: 16px;
    color: #999;
  }
  

@media( max-width: 1024px)
    {

.purchaseRadio{
    label{
        font-size: 1.5rem;
    }
}
    }