.time-category {
    display: flex;
    align-items: stretch;
    margin-bottom: 20px;
  }

  .vertical-label-container{
    width: 4rem;
    position: relative;
    align-items: center;
    display: flex;
  }

  .vertical-label-wrapper{

  }
  
  .vertical-label {
    font-size: 2rem;
    position: absolute;
    transform: rotate(-90deg);
    font-weight: bold;
    transform-origin: top left;
    padding-right: 2rem;
    top: 65%;
    display: flex;
    align-items: center;
}
  
  .service-list {
    flex-grow: 1;
  }

  .time-slot-container{
    flex-grow: 1;
    padding-left: 1rem;
    position: relative;
  }

  .morning-color{
    background-color: var(--orange);
    opacity: 0.6;
  }

  .afternoon-color{
    background-color: var(--teal);
    opacity: 0.6;
  }

  .evening-color{
    background-color: var(--red);
    opacity: 0.6;
  }

 
  .time-slot-color{
   align-items: stretch;
    width: 1rem;
  }
  
  .unavailable-category-bg{
    background-color: #f0f0f0;
  }

  .appointment-card-body{
    background-color: transparent;
  }

  .time-icon-container{
    position: absolute;
    left: -40px;
    transform: rotate(90deg);
    background-color: var(--orange);
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 50%;
  }


  .time-icon{
    /* padding: 5px; */
    /* background-color: var(--orange); */
    /* border-radius: 50%; */
   
  }

  .waitlist-btn-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    
    
  }

  .service-waitlist-btn{
    background-color: var(--orange);
    padding: 10px 20px;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 1rem;
  }